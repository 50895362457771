/* 전체 화면을 덮는 오버레이 */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 팝업을 맨 위에 놓기 */
  pointer-events: none; /* 오버레이 자체는 클릭을 차단하지 않음 */
}

/* 팝업 창 */
.popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px; /* 팝업 창의 너비 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  position: absolute;
  top: 15%;
  left: 35%;
  pointer-events: auto; /* 팝업은 클릭 가능하도록 설정 */
}

/* 팝업 헤더 */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.popup-header h3 {
  margin: 0;
}

.popup-header .close-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

/* 팝업 본문 */
.popup-body {
  margin: 20px 0;
  font-size: 16px;
  color: #555;
}

/* 팝업 바닥 */
.popup-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* 체크박스와 버튼을 세로로 중앙 정렬 */
}

/* 체크박스 스타일링 */
.popup-footer input[type='checkbox'] {
  width: 15px; /* 체크박스 크기 조절 */
  height: 15px; /* 체크박스 크기 조절 */
  margin-right: 5px; /* 체크박스와 텍스트 사이 간격 */
  cursor: pointer; /* 마우스 커서 스타일 */
}

/* 체크박스 체크 색상 변경 */
.popup-footer input[type='checkbox']:checked {
  background-color: #007bff; /* 체크 시 배경 색상 */
  border-color: #007bff; /* 체크 시 테두리 색상 */
}

/* 체크박스 체크 시 내부 색상 */
.popup-footer input[type='checkbox']:checked::after {
  background-color: white; /* 체크된 상태에서 내부 색상을 흰색으로 */
}

/* 체크박스 label 스타일링 */
.popup-footer label {
  font-size: 14px; /* 텍스트 크기 조절 */
  margin-right: 15px; /* 각 체크박스 사이 간격 */
  cursor: pointer; /* 마우스 커서 스타일 */
  display: inline-flex; /* 수평 정렬 */
  align-items: center; /* 텍스트와 체크박스 수직 중앙 정렬 */
}

/* 버튼 스타일 */
.popup-footer .close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-footer .close-button:hover {
  background-color: #0056b3;
}
