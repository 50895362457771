/* 각 항목 크기 조절 */
.ranking-item {
  padding: 5px; /* 기존 10px → 5px */
  border-radius: 4px; /* 기존 6px → 4px */
  height: 40px; /* 기존 80px → 40px */
}

/* 순위 크기 줄이기 */
.ranking-item .rank {
  font-size: 12px; /* 기존 크기보다 줄이기 */
}

/* 로고 크기 조정 */
.ranking-item .logo {
  flex: 0 0 25px; /* 기존 50px → 25px */
  width: 25px;
  height: 25px;
}

/* 채널 정보 */
.ranking-item .channel-info {
  padding-left: 10px; /* 기존 20px → 10px */
  padding-right: 5px; /* 기존 10px → 5px */
}

.ranking-item .channel-name {
  font-size: 14px; /* 기존보다 작게 */
}

.ranking-item .subscribers {
  margin-top: 4px; /* 기존 4px → 2px */
  font-size: 12px; /* 기존보다 작게 */
}

/* 채널 설명 */
.ranking-item .channel-description {
  padding: 0 5px; /* 기존 10px → 5px */
  font-size: 10px;
}

/* 두 줄 표시되는 설명 */
.ranking-item .channel-description-multi {
  -webkit-line-clamp: 2;
  font-size: 10px; /* 기존보다 작게 */
}

/* 좋아요 영역 */
.ranking-item .likes {
  flex: 0 0 30px; /* 기존 80px → 40px */
  gap: 5px; /* 기존 10px → 5px */
}

.ranking-item .likes-icon {
  width: 10px; /* 기존 20px → 10px */
  height: 10px;
}

.ranking-item .like-count {
  font-size: 10px; /* 기존보다 작게 */
}

/* Swiper 부모 컨테이너의 크기 */
.swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; /* 부모 요소의 넘치는 부분을 잘라냄 */
}

/* SwiperSlide의 크기 및 세로 슬라이드 설정 */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* 세로로 아이템 그룹이 나열되도록 설정 */
  width: 100%;
  padding: 0; /* 슬라이드 안의 여백 제거 */
}

/* 슬라이드 그룹을 세로로 나열 */
.swiper-slide-group {
  display: flex;
  flex-direction: column; /* 세로 방향으로 그룹 나열 */
  justify-content: space-between; /* 각 항목 간에 고른 간격 배분 */
  width: 100%;
  gap: 5px; /* 아이템들 사이의 간격 추가 (필요에 따라 조정) */
}
