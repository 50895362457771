.ranking-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 50px;
}

.ranking-title {
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* 랭킹 항목 컨테이너 */
.ranking-container {
  display: flex;
  flex-direction: column; /* 항목들을 세로로 나열 */
  gap: 10px; /* 항목 간 간격 설정 */
}

/* 각 항목 */
.ranking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #10b759;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  height: 80px;
  overflow: hidden;
}

/* 순위 */
.ranking-item .rank {
  flex: 0 0 50px; /* 고정 너비 */
  text-align: center;
  font-weight: bold;
}

/* 로고 */
.ranking-item .logo {
  flex: 0 0 50px; /* 고정 너비 */
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.ranking-item .logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 채널 정보 */
.ranking-item .channel-info {
  display: flex;
  flex-direction: column;
  flex: 1; /* 나머지 공간 차지 */
  justify-content: center; /* 수직 중앙 정렬 */
  padding-left: 20px; /* 왼쪽 여백 */
  padding-right: 10px; /* 오른쪽 여백 */
  cursor: pointer;
}

.ranking-item .channel-name {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  white-space: nowrap; /* 한 줄로 표시 */
  overflow: hidden; /* 넘치는 내용 숨김 */
  text-overflow: ellipsis; /* 넘치는 내용 말줄임표 */
}

.ranking-item .subscribers {
  margin-top: 4px;
  font-size: 14px;
  text-align: left;
}

/* 채널 설명 */
.ranking-item .channel-description {
  flex: 3; /* 더 넓은 공간 차지 */
  font-size: 14px;
  padding: 0 10px;
  text-align: left;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis; /* 한 줄 넘을 때 말줄임표 */
  white-space: nowrap;
}

.ranking-item .channel-description-multi {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 최대 두 줄까지 표시 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  word-break: break-word; /* 긴 단어가 있을 경우 잘라서 표시 */
}

/* 각 항목에 hover 효과 추가
.ranking-item:hover {
  background-color: #00a428; 
  cursor: pointer; 
} */

/* 좋아요 아이콘 및 갯수 */
.ranking-item .likes {
  display: flex;
  flex: 1; /* 더 넓은 공간 차지 */
  gap: 10px;
  flex: 0 0 80px; /* 고정 너비 */
}

.ranking-item .likes-icon {
  align-items: center;
  justify-items: left;
  width: 20px;
  height: 20px;
}

.ranking-item .like-count {
  font-size: 16px;
  display: flex;
  align-items: center; /* 아이콘과 숫자를 수직 정렬 */
  text-align: right;
}
