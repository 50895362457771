/* marker-box 스타일 */
.marker-box {
  border: 2px solid #d09d39;
  background: white;
  /* color: #bdbdbd; */
  padding: 8px 12px;
  border-radius: 12px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition:
    background 0.3s ease,
    color 0.3s ease;
}

/* hover 시 스타일 */
.marker-box:hover {
  background-color: #d09d39;
  color: white;
}

/* 포인터 부분 스타일 */
.pointer {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #d09d39;
}
