.chat-container {
  display: flex;
  justify-content: flex-end; /* 아바타를 오른쪽에 배치 */
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: 10px; /* 아바타 왼쪽 여백 */
  display: flex; /* 중앙 정렬을 위해 flex 사용 */
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  font-weight: bold; /* 글씨 굵게 */
  color: white; /* 글씨 색상 */
}

.speech-bubble {
  position: relative;
  background: transparent; /* 배경 제거 */
  padding: 10px 15px;
  border-radius: 6px;
  width: 100%; /* 페이지에 맞춰 너비 자동 조정 */
  text-align: left;
  border: 2px solid #f1f1f1; /* 테두리 추가 */
  color: #333; /* 글자 색상 */
  box-sizing: border-box; /* padding을 포함한 전체 크기를 조정 */
}
